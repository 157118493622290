
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as Projects from '../../types/projects.types';
import { SelectOption } from '../form/form.types';
import * as API from '@/api/projects.api';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GskAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { GenericCiIds } from '@/Rpa/constants';

interface AutocompleteEvent extends CustomEvent {
  name: string;
  value: string;
}

@Component({
  components: {
    Grid,
    GridCell,
    GTextfield,
    GskAutocomplete,
  },
})
export default class CiFields extends Vue {
  @Prop({ required: true, type: String }) readonly id!: string;
  @Prop({ required: true, type: String }) readonly name!: string;
  @Prop({ required: true, type: String }) readonly infoClassification!: string;
  @Prop({ required: true, type: String }) readonly pType!: string;
  @Prop({ required: true, type: String }) readonly gxp!: string;
  @Prop({ required: true, type: String }) readonly sox!: string;
  @Prop({ required: false, type: Boolean, default: false }) readonly required!: boolean;

  private loadingConfigurationName = false;
  private loadingCiDetails = false;

  get defaultChoices(): SelectOption[] {
    const choices = [];
    if (this.name.length) {
      choices.push({
        label: this.name,
        value: this.name,
      });
    }
    return choices;
  }

  get isGenericCi(): boolean {
    return GenericCiIds.includes(this.id);
  }

  async getConfigurationIdChoices(query: string): Promise<Projects.ServiceNowIdLabel[]> {
    this.loadingConfigurationName = true;
    const choices = await API.getServiceNowIds(query).finally(() => {
      this.loadingConfigurationName = false;
    });
    return choices.data.map(res => ({ label: `${res.name}`, value: res.id, name: res.name }));
  }

  async getConfigurationIdDetails(id: string): Promise<Projects.ConfigurationName> {
    const resp = await API.getConfigurationIdName(id);
    return resp.data;
  }

  async setConfigurationId(e: AutocompleteEvent): Promise<void> {
    if (!e) {
      return;
    }
    this.loadingCiDetails = true;
    const { name, value } = e;
    this.$emit('update:id', value);
    this.$emit('update:name', name);
    await this.getConfigurationIdDetails(value)
      .then(ciDetails => {
        const { u_pii_type, u_information_classification, u_gxp, u_sarbanes_oxley } = ciDetails;
        this.$emit('update:info-classification', u_information_classification);
        this.$emit('update:p-type', u_pii_type);
        this.$emit('update:sox', u_sarbanes_oxley);
        this.$emit('update:gxp', u_gxp);
        this.loadingCiDetails = false;
      })
  }

  get ciOption(): SelectOption {
    return {
      label: this.name,
      value: this.name,
    };
  }
}
