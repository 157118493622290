
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import 'highlight.js/styles/github.css';
import { marked, replaceLinkedRepoRelativeLinks } from '@/utils/markdown';

import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { PublishingModule } from '@/store/modules/publishing.module';
import MaxWidth from '@/components/MaxWidth.vue';

import * as API from '@/api/github.api';
import { ListingSectionTemplateTypeIds, ListingSectionTemplateTypes } from '@/constants';
import { ListingSection } from '@/types/listings.types';
import GAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    GTextfield,
    MaxWidth,
    GAutocomplete,
    GButton,
  },
})
export default class LinkEditor extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly open!: boolean;
  @Prop() readonly link!: string;
  @Prop({ type: Number, required: true }) readonly index!: number;

  public userURL = '';
  public displayText = '';

  public get renderedMarkdown(): string {
    if (this.githubRepo) {
      return replaceLinkedRepoRelativeLinks(
        marked(this.displayText, true),
        this.githubRepo,
        this.userURL,
      );
    } else {
      return marked(this.displayText, { baseUrl: this.userURL });
    }
  }

  @Emit('update:open')
  public update(isOpen: boolean): boolean {
    return isOpen;
  }

  get disabled() {
    return this.userURL === '';
  }

  get githubRepo() {
    return PublishingModule.draftListing.extendedProperties.githubRepo;
  }

  get fileChoice() {
    return this.userURL ? { label: this.userURL, value: this.userURL } : null;
  }
  set fileChoice(fc: { label: string; value: string } | null) {
    this.inputChange(fc ? fc.value : '');
  }

  private fileCache: { label: string; value: string }[] | null = null;
  filterFiles(v: string) {
    return (this.fileCache || []).filter(f => new RegExp(v, 'i').test(f.label));
  }
  get filesKey() {
    return JSON.stringify(this.fileCache);
  }

  get hasMarkdownFiles(): boolean {
    if (this.fileCache === null) {
      return true; // not loaded yet, pretend it does
    }

    return !!this.fileCache.length;
  }

  async getFiles(v: string) {
    if (this.githubRepo) {
      const [owner, repo] = this.githubRepo.full_name.split('/');
      this.$log('owner, repo', owner, repo);
      if (this.fileCache) {
        return this.filterFiles(v);
      }
      return API.getRepoFiles(owner, repo)
        .then(r => r.data)
        .then(files => files.filter(f => f.endsWith('.md')))
        .then(files => (this.fileCache = files.map(f => ({ label: f, value: f }))))
        .then(() => this.filterFiles(v))
        .finally(() => {
          if (!this.hasMarkdownFiles) {
            this.displayText = '## Your GitHub repo does not contain any markdown files';
          }
        });
    }
    return [];
  }
  get forceGithub() {
    return PublishingModule.listingTypeTemplate.publishing.githubRepo.canLink;
  }

  async created() {
    this.userURL = this.link;
    if (this.forceGithub) {
      this.getFiles('.');
      this.inputChange(this.userURL);
    }
  }

  public async inputChange(val: string): Promise<void> {
    this.$log('input change', val);
    this.userURL = val.trim();
    if (!this.userURL) {
      this.displayText = '## Please enter a link to a markdown file';
      return;
    }
    if (this.forceGithub) {
      if (this.githubRepo) {
        const [owner, repo] = this.githubRepo.full_name.split('/');
        this.displayText = (await API.getRepoFileContents(owner, repo, val)).data;
      }
      return;
    }
    try {
      this.displayText = (await API.getExternalDocumentation(this.userURL)).data;
    } catch (e) {
      this.displayText = `
## Error loading content

##### please verify that the url you have entered is accessible and is a direct link to a markdown file
      `;
    }
  }

  public cancel(): void {
    this.update(false);
  }

  public get pageTitle(): string {
    return this.title;
  }

  public close(): void {
    this.update(false);
  }

  public save(): void {
    const listingSection: ListingSection = {
      sectionName: this.title,
      sectionTemplateType: ListingSectionTemplateTypes.Markdown,
      sectionContent: '',
      sectionContentUrl: this.userURL,
      sectionTemplateTypeId: ListingSectionTemplateTypeIds[ListingSectionTemplateTypes.Markdown],
    };

    this.$emit('save', listingSection);
    this.close();
  }
}
