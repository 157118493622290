
import { Component, Prop, Vue } from 'vue-property-decorator';
import get from 'lodash/get'; 
import { AxiosError } from 'axios';
import { Promised } from 'vue-promised';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { GithubRepo } from '@/types/publishing.types';
import { SelectOption } from '@/components/form/form.types';
import { getUserGithubRepos } from '@/api/publishing.api';
import { UserModule } from '@/store/modules/user.module';
import { sanitize } from '@/utils/components';

const EMPTY = '__empty__';
type Opt = SelectOption<GithubRepo | null>;

@Component({
  components: {
    GSelect,
    Promised,
  },
  inheritAttrs: false,
})
export default class RepoPicker extends Vue {
  @Prop({ required: true, validator: prop => prop === null || typeof prop.name === 'string' })
  value!: GithubRepo | null;
  @Prop({ type: String, default: 'GitHub Repo' }) label!: string;
  @Prop(Boolean) required!: boolean;
  @Prop(Boolean) noneOption!: boolean;
  @Prop(Boolean) templateOnly!: boolean;

  p: Promise<any> | null = null;
  ghOptions: Opt[] = [];
  repo: GithubRepo | null = this.value ? this.value : null;
  userNotFound = false;
  repoNotFound = false;

  get options(): Opt[] {
    if (this.noneOption) {
      const noneOption: Opt = {
        label: 'No Repo',
        value: EMPTY,
        extra: null,
      };

      return [noneOption].concat(this.ghOptions);
    }

    return this.ghOptions || [];
  }

  get repoUniqueId(): string {
    if (this.value === null) {
      if (this.noneOption) {
        return EMPTY;
      }
      return '';
    }

    return this.value.full_name;
  }

  set repoUniqueId(id: string) {
    if (id === EMPTY) {
      this.$emit('input', null);
    }
    const r = (this.options.find(o => o.value === id) || { extra: null }).extra || null;
    this.repo = r;
    this.$emit('input', r);
  }

  get noUserLink() {
    return sanitize(`https://github.com/${UserModule.user.mudId}_gsk`);
  }

  created() {
    this.p = UserModule.userPromise // ensure we have the real mudId
      .then(() => getUserGithubRepos(UserModule.user.mudId))
      // .then(() => getUserGithubRepos('use this one to simulate no user'))
      .then(r => {
        if (this.templateOnly) {
          return r.data.filter(repo => repo.is_template);
        }
        return r.data;
      })
      .then(data =>
        data.map(
          (repo): SelectOption<GithubRepo> => ({
            value: repo.full_name,
            label: repo.full_name,
            extra: repo,
          }),
        ),
      )
      .then(options => {
        this.ghOptions = options;
        if (this.value?.full_name) {
          const repo = this.ghOptions.find(repo => repo.value === this.value?.full_name);
          if (!repo) {
            this.repoNotFound = true;
            this.$emit('norepo');
          }
        }
      })
      .catch((e: AxiosError) => {
        const code = get(e, 'response.status');
        if (code === 404) {
          this.userNotFound = true;
          this.$emit('nouser');
        } else {
          throw e;
        }
      });
  }
}
