
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
import { FullListing, ListingSection } from '@/types/listings.types';
import { UINavigationItem } from '@/types';
import { ListingsModule as ListingCatalogModule } from '@/store/modules/listings.module';
import MaxWidth from '@/components/MaxWidth.vue';
import ListingDetailInfoView from './ListingDetailInfo.vue';
import { slugify } from '@/utils/routing';
import ListingDetails from '@/components/listing/ListingDetails.vue';
import { addAnalyticsRouteParams } from '@/analytics';
import { listingTypesById, RouteNames } from '@/constants';
import { EnumsModule } from '@/store/modules/enums.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';

// tab props
const props = {
  minwidth: true,
  noripple: true,
};

@Component({
  components: {
    GButton,
    NavigationTabs,
    MaxWidth,
    ListingDetailInfoView,
    ListingDetails,
  },
})
export default class ListingDetailView extends Vue {
  @Prop() readonly listingId!: number;
  @Prop() readonly listing!: FullListing;
  @Prop() readonly draft!: boolean;
  public ready = false;
  private tryItQueryParams: {
    referrerProjectId?: string,
    referrerProjectEnvId?: string,
    referrerServiceId?: string,
  } = {};
  public listingDetail: FullListing|undefined;
  public error: null | AxiosError = null;
  get errorMessage(): string | null | undefined {
    return this.error?.response?.data?.message ?? this.error?.message;
  }

  public tab: UINavigationItem = {
    props,
    text: '',
    key: '',
    replace: true,
    route: {},
  };

  public get currentListing(): FullListing {
    if (this.draft) {
      return this.listing;
    } else {
      return ListingCatalogModule.detailedListing;
    }
  }

  formatNoRouteTab(tab: ListingSection): UINavigationItem {
    return {
      ...tab,
      props,
      text: tab.sectionName,
      key: tab.sectionName,
      replace: false,
      route: {},
    };
  }

  get listingTypeName() {
    const id = this.currentListing.listingTypeId;
    return EnumsModule.enumsById.listingType[id].name;
  }

  formatRouteTab(section: ListingSection, index: number): UINavigationItem {
    return {
      ...section,
      props,
      text: section.sectionName,
      key: index.toString(),
      replace: true,
      route: {
        name: RouteNames.ListingSection,
        params: {
          type: slugify(this.listingTypeName || 'listing'),
          name: slugify(this.currentListing.listingName),
          info: slugify(section.sectionName),
        },
        query: {
          ...this.$route.query,
          ...this.tryItQueryParams,
        },
      },
    };
  }

  public get tabBarProps() {
    return {
      noripple: true,
    };
  }

  public get tabs(): UINavigationItem[] {
    let docSections = this.currentListing.extendedProperties.documentationSections.map(
      s => s.sectionInfo,
    );
    if (!Array.isArray(docSections)) {
      docSections = [];
    }
    return docSections.map((section: ListingSection, i: number) =>
      this.draft ? this.formatNoRouteTab(section) : this.formatRouteTab(section, i),
    );
  }

  @Watch('$route.query.version')
  versionWatcher() {
    if (this.ready && this.currentListing.versions?.length) {
      this.resolveListingVersion();
    }
  }

  async resolveListingVersion() {
    const versions = this.currentListing.versions;

    // resolve version
    if (versions?.length) {
      // it's versioned within our system
      const routeVersion = this.$route.query.version;
      let versionId;
      if (routeVersion) {
        // try to match routeVersion to an actual version
        versionId =
          versions.find(version => version.listingVersionId.toString() === routeVersion)
            ?.listingVersionId ?? versions[0].listingVersionId;
      } else {
        versionId = versions[0].listingVersionId;
      }
      if (this.currentListing.listingVersionId !== versionId) {
        await ListingCatalogModule.getListingVersionDetails(versionId);
      }
    }
  }

  protected async created(): Promise<void> {
    if (FeatureFlagsModule.tryitEnabled) {
      let tryItQueryParams: {
        referrerProjectId?: string,
        referrerProjectEnvId?: string,
        referrerServiceId?: string,
      } = this.$route.query;

      this.tryItQueryParams = tryItQueryParams;
    }

    if (!this.draft) {
      ListingCatalogModule.getCatalogListingTypes();
      if (
        this.listingId.toString() !==
        (ListingCatalogModule.currentListing.listingId || 0).toString()
      ) {
        ListingCatalogModule.resetCurrentListing();
      }
      try {
        this.listingDetail = await ListingCatalogModule.getListingDetails(this.listingId);
      } catch (e) {
        this.error = e;
        return;
      }
      await this.resolveListingVersion();

      if (!this.$route.params.info && this.tabs[0]) {
        const route = this.tabs[0].route;
        const analyticsData = {
          listingName: this.currentListing.listingName,
          listingType: listingTypesById[this.currentListing.listingTypeId],
        };
        if (typeof route === 'string') {
          const r: Location = this.$router.resolve(route).location;
          r.params = addAnalyticsRouteParams(r.params, analyticsData);
          await this.$router.replace(r).catch(e => this.$log(e));
        } else {
          const r: Location = { ...route };
          r.params = addAnalyticsRouteParams(r.params, analyticsData);
          await this.$router.replace(r).catch(e => this.$log(e));
        }
      }
    } else {
      if (this.tabs[0]) {
        this.tab = this.tabs[0];
      }
    }

    this.ready = true;
  }
}
