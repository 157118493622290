
import { Component, Prop, Vue } from 'vue-property-decorator';
import FullScreenForm from '@/components/FullScreenForm.vue';
import * as Projects from '@/types/projects.types';
import { RouteNames } from '@/constants';
import { openSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import BotDetailsForm from './BotDetailsForm.vue';
import GskButton from '@/components/gsk-components/GskButton.vue';
import { getDraftListing } from '@/api/publishing.api';
import { BaseUser } from '@/types/users.types';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import cloneDeep from 'lodash/cloneDeep';

const YES = 'Yes';
const NO = 'No';
const isEdit = true;

@Component({
  components: {
    FullScreenForm,
    BotDetailsForm,
    GskButton,
  },
})
export default class EditBotDetails extends Vue {
  protected YES: string = YES;
  protected NO: string = NO;
  @Prop({ type: Boolean, required: true }) hasRetrievedBot!: boolean;

  get botName(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.botName;
  }

  set botName(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({key: 'botName', value: str});
    ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'listingName', value: str});
  }

  get botDescription(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.botDescription;
  }

  set botDescription(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({key: 'botDescription', value: str});
    ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'listingDescription', value: str});
  }

  get systemIntegrations(): string[] {
    return ProjectDetailsRpaModule.getEditBotDetails.systemIntegrations;
  }

  set systemIntegrations(strs: string[]) {
    ProjectDetailsRpaModule.setEditBotKeyVal({key: 'systemIntegrations', value: strs});
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'systems-integrations', value: strs});
  }

  get businessProcess(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.businessProcess;
  }

  set businessProcess(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'businessProcess', value: str });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'bp', value: str});
  }

  get businessOwner(): BaseUser {
    return ProjectDetailsRpaModule.getEditBotDetails.businessOwner;
  }

  set businessOwner(user: BaseUser) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'businessOwner', value: user });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'bo', value: user});
  }

  get riskPartner(): BaseUser {
    return ProjectDetailsRpaModule.getEditBotDetails.riskPartner;
  }

  set riskPartner(user: BaseUser) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'riskPartner', value: user });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'riskPartner', value: user});
  }

  get businessUnit(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.businessUnit;
  }

  set businessUnit(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'businessUnit', value: str});
  }

  get deliveredFor(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.deliveredFor;
  }

  set deliveredFor(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'deliveredFor', value: str});
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'delivered-for', value: str});
  }

  get configurationId(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.configurationId;
  }

  set configurationId(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({key: 'configurationId', value: str});
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'config-id', value: str});
  }

  get configurationIdName(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.configurationIdName;
  }

  set configurationIdName(str: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'configurationIdName', value: str});
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'config-id-name', value: str});
  }

  get infoClassification(): string {
    const ref = ProjectDetailsRpaModule.getEditBotDetails;
    const ic = ref.snowData?.snowDetails?.infoClassification;
    return ic? ic: '';
  }

  set infoClassification(str: string) {
    let { snowData } = ProjectDetailsRpaModule.getEditBotDetails;
    let { snowDetails } = snowData;
    snowDetails = { ...snowDetails, infoClassification: str };
    snowData = { snowDetails: { ...snowDetails } };
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'snowData', value: snowData });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'infoClassification', value: str});
  }

  get pType(): string {
    const pType = ProjectDetailsRpaModule.getEditBotDetails.snowData?.snowDetails?.pType;
    return pType? pType: '';
  }

  set pType(str: string) {
    let { snowData } = ProjectDetailsRpaModule.getEditBotDetails;
    let { snowDetails } = snowData;
    snowDetails = { ...snowDetails, pType: str };
    snowData = { snowDetails: { ...snowDetails } };
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'snowData', value: snowData });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'pType', value: str});
  }

  get gxp(): string {
    const gxp = ProjectDetailsRpaModule.getEditBotDetails.snowData?.snowDetails?.gxp;
    return gxp? gxp: '';
  }

  set gxp(str: string) {
    let { snowData } = ProjectDetailsRpaModule.getEditBotDetails;
    let { snowDetails } = snowData;
    snowDetails = { ...snowDetails, gxp: str };
    snowData = { snowDetails: { ...snowDetails } };
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'snowData', value: snowData });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'gxp', value: str});
  }

  get sox(): string {
    const sox = ProjectDetailsRpaModule.getEditBotDetails.snowData?.snowDetails?.sox;
    return sox? sox: '';
  }

  set sox(str: string) {
    let { snowData } = ProjectDetailsRpaModule.getEditBotDetails;
    let { snowDetails } = snowData;
    snowDetails = { ...snowDetails, sox: str };
    snowData = { snowDetails: { ...snowDetails } };
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'snowData', value: snowData });
    ProjectDetailsRpaModule.setEditBotListingDetailsExtPropDetails({key: 'sox', value: str});
  }

  get isOnCyberArk(): string {
    return ProjectDetailsRpaModule.getEditBotDetails.isOnCyberArk ? YES : NO;
  }

  set isOnCyberArk(isOn: string) {
    ProjectDetailsRpaModule.setEditBotKeyVal({ key: 'isOnCyberArk', value: isOn === YES });
  }

  get cyberArkBool(): boolean {
    return this.isOnCyberArk === YES;
  }

  get keywords(): string {
    if (ProjectDetailsRpaModule && ProjectDetailsRpaModule.getEditBotListingDetails && ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties) {
      const { keywords } = ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties;
      if (keywords) {
        return keywords.join(' ');
      }
    }
    return '';
  }

  addKeywords(words: string[]): void {
    const keywords = words.flatMap(v => v.split(/,/g)).filter(w => !!w);
    ProjectDetailsRpaModule.updateKeywords({keywords, isEdit});
  }

  get isPrivate(): string {
    return Number(ProjectDetailsRpaModule.getEditBotListingDetails.isPrivate).toString();
  }

  set isPrivate(str: string) {
    ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'isPrivate', value: Boolean(Number(str))});
  }

  async created(): Promise<void> {
    if (!this.hasRetrievedBot) {
      await ProjectDetailsModule.getProject(this.$route.params.projectId)
        .then(async () => {
          const temp = ProjectDetailsModule.projectDetails.bots.filter(
            bot => bot.botRegistrationId === Number(this.$route.params.botRegistrationId),
          )[0];
          const bot = cloneDeep(temp);
          let ld: any;
          if (bot.listingId) {
            ld = (await getDraftListing(bot.listingId)).data;
          } else {
            ld = ProjectDetailsRpaModule.initRpaDraftListing;
          }
          ProjectDetailsRpaModule.setEditBotListingDetails(ld);
          await ProjectDetailsRpaModule.getRpaListingTypes();
          ProjectDetailsRpaModule.setEditBotDetails(bot);
          this.$emit('update:has-retrieved-bot', true);
        })
        .catch(e => {
          openSnackbar.call(this, e.message, { type: 'error' });
        });
    }
  }

  next(): void {
    this.$emit('next');
  }

  get isNotComplete(): boolean {
    return (
      !this.botName ||
      !this.botDescription ||
      !this.systemIntegrations.length ||
      !this.businessOwner ||
      !this.businessUnit ||
      !this.deliveredFor
    );
  }

  get projectId() {
    return this.$route.params.projectId;
  }

  get envId() {
    return this.$route.params.envId;
  }

  protected get editBot(): Projects.UpdateProjectBot {
    return {
      botName: this.botName,
      botDescription: this.botDescription,
      systemIntegrations: this.systemIntegrations,
      businessProcess: this.businessProcess,
      businessOwner: this.businessOwner,
      businessUnit: this.businessUnit,
      riskPartner: this.riskPartner,
      deliveredFor: this.deliveredFor,
      configurationId: this.configurationId,
      configurationIdName: this.configurationIdName,
      snowDetails: {
        infoClassification: this.infoClassification,
        pType: this.pType,
        gxp: this.gxp,
        sox: this.sox,
      },
      isOnCyberArk: this.cyberArkBool,
    };
  }

  backToRpaProjectPage(): void {
    this.$router.replace({
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
      },
    });
  }
}
