
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import * as API from '@/api/github.api';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { RequiredProp } from '@/utils/components';
import { getProxyUrl, safeUrl } from '@/api/service/urls';
import { ListingSectionTemplateTypeIds, ListingSectionTemplateTypes } from '@/constants';
import GButton from '@/components/gsk-components/GskButton.vue';
import FileSelect from '@/components/catalog/FileSelect/FileSelect.vue';
import { ListingSection } from '@/types/listings.types';
import * as Yaml from 'yaml';

@Component({
  components: {
    'monaco-editor': () => import('vue-monaco'),
    GTextfield,
    GButton,
    FileSelect,
  },
})
export default class OpenApiSpecLink extends Vue {
  @Prop() readonly open!: boolean;
  @Prop() readonly title!: string;
  @Prop() readonly link!: string;
  @RequiredProp(Object) readonly docSection!: ListingSection;
  @RequiredProp(Number) readonly index!: number;
  @Prop(Boolean) readonly edit!: boolean;

  // private editor: import('monaco-editor').editor.IEditor | null = null;
  private editor: any = null;

  public userURL = '';

  public repoName = '';
  public code = '';
  public specUrl = '';
  public specLoadError = false;

  public onResize = debounce(this.resizeEditor.bind(this), 200);

  created() {
    window.addEventListener('resize', this.onResize);
    if (!this.edit) {
      this.handleUrlUpdate(this.link);
    } else {
      this.code = this.docSection.sectionContent;
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  get editorLanguage() {
    try {
      JSON.parse(this.code);
      return 'json';
    } catch (e) {
      return 'yaml';
    }
  }

  get monacoOptions(): import('monaco-editor').editor.IEditorConstructionOptions {
    return {
      readOnly: !this.edit,
      formatOnPaste: true,
      formatOnType: true,
      minimap: {
        enabled: false,
      },
    };
  }

  public onRapiDocSpecLoaded(e: CustomEvent): void {
    this.specLoadError = e.detail.specLoadError;
  }

  loadCode(fileCode: string): void {
    this.code = fileCode;
  }

  @Watch('code')
  public handleResize() {
    if (this.edit) {
      this.specUrl = this.makeFileUrl(this.code);
    }
    this.resizeEditor();
  }

  public makeFileUrl(str: string): string {
    this.$nextTick(() => {
        const openRefRapidoc = this.$refs['open-api-rapidoc'];
        if(openRefRapidoc) {
          try {
            (openRefRapidoc as any).loadSpec(JSON.parse(str) || {});
          }catch(jsonParseError) {
            try {(openRefRapidoc as any).loadSpec(Yaml.parse(str) || {}); }
            catch(yerr) { this.specUrl = ''}
          }
        }
    });
    return '';
  }

  public resizeEditor() {
    if (this.editor) {
      this.editor.layout();
    }
  }

  get isDisabled(): boolean {
    if (this.specLoadError) {
      return true;
    }
    if (this.edit) {
      return !this.code;
    }
    return !this.userURL;
  }

  @Emit('update:open')
  public update(isOpen: boolean): boolean {
    return isOpen;
  }

  handleUrlUpdate(value: string) {
    this.userURL = value.trim();
    this.getExternalLink();
  }

  async getExternalLink() {
    if (this.userURL) {
      const formattedUrl = safeUrl(this.userURL);
      this.specUrl = getProxyUrl(formattedUrl);
      const result = (await API.getExternalDocumentation(formattedUrl)).data;
      this.code = typeof result === 'string' ? result : JSON.stringify(result, null, 2);
    }
  }

  saveDoc() {
    const sectionTemplateType = ListingSectionTemplateTypes.OpenApi;
    const listingSection: {
      sectionName: string;
      sectionTemplateTypeId: number;
      sectionContent: string;
      sectionTemplateType: ListingSectionTemplateTypes;
      sectionContentUrl?: string;
    } = {
      sectionName: this.title,
      sectionTemplateType,
      sectionContentUrl: this.userURL,
      sectionContent: '',
      sectionTemplateTypeId: ListingSectionTemplateTypeIds[sectionTemplateType],
    };
    if (this.edit) {
      delete listingSection.sectionContentUrl;
      listingSection.sectionContent = this.code;
    }

    this.$emit('save', listingSection);
    this.close();
  }

  public close(): void {
    this.update(false);
  }

  formatJsonCode() {
    if (this.code && this.editorLanguage === 'json') {
      this.code = JSON.stringify(JSON.parse(this.code), null, 2);
    }
  }

  editorDidMount(editor: import('monaco-editor').editor.ICodeEditor) {
    this.editor = editor;
    setTimeout(() => {
      this.formatJsonCode();
    }, 16);
  }
}
