
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import EditBotDetails from '@/components/projects/EditBotDetails.vue';
import RpaDocumentation from '@/components/projects/RpaDocumentation.vue';
import { UITab } from '@/types';
import * as Projects from '@/types/projects.types';
import * as API from '@/api/projects.api';
import { openSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import NavigationList from '@/components/NavigationList.vue';
import { DraftListingModule } from '@/types/listings.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    EditBotDetails,
    NavigationList,
  },
})
export default class EditRpaBot extends Vue {
  @Prop({ required: true, type: String }) readonly envId!: string;
  @Prop({ required: true, type: String }) readonly projectId!: string;
  protected test = 'poop';
  protected tabIndex = 0;
  protected isEdit = true;
  protected hasRetrievedBot = false;

  private modalClosed = false;

  protected tabList: UITab[] = [
    {
      component: 'EditBotDetails',
      disabled: false,
      key: 'bot-details',
      text: 'Edit bot',
    },
    {
      component: 'RpaDocumentation',
      disabled: false,
      key: 'rpa-add-documentation',
      text: 'Documentation',
    },
  ];

  protected editActiveTab: UITab = this.tabList[this.tabIndex];

  protected listingDetails: DraftListingModule = ProjectDetailsRpaModule.initRpaDraftListing;

  updateKeywords(words: string[]): void {
    let { extendedProperties } = ProjectDetailsRpaModule.getEditBotListingDetails;
    const ep = { ...extendedProperties, keywords: words };
    ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({ key: 'extendedProperties', value: ep});
  }

  async closeEdit(): Promise<void> {
    await ProjectDetailsRpaModule.resetBotDetailsState();
    await this.$router.replace(this.closeToRpaProjectRoute);
  }

  public get closeToRpaProjectRoute(): RawLocation {
    return {
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
      },
    };
  }

  public get currentComponent(): Vue.VueConstructor | void {
    const c = this.editActiveTab.component || '';
    let components: { [key: string]: Vue.VueConstructor } = {
      EditBotDetails,
      RpaDocumentation,
    };

    return components[c];
  }

  public get activeTab(): UITab {
    return this.editActiveTab;
  }

  public set activeTab(tab: UITab) {
    this.editActiveTab = tab;
  }

  protected get botRegistrationId(): number {
    return Number(this.$route.params.botRegistrationId);
  }

  public nextComponent(): void {
    const i = this.tabList.findIndex((tab: UITab): boolean => tab.key === this.editActiveTab.key);
    const nextIndex = i + 1;
    const lastIndex = this.tabList.length - 1;
    if (i === -1 || i === lastIndex) {
      return;
    } else {
      const nextTab = this.tabList[nextIndex];
      nextTab.disabled = false;
      this.editActiveTab = nextTab;
    }
  }

  async save(listing: DraftListingModule): Promise<void> {
    listing.businessUnitId = EnumsModule.enumsLists.businessUnit.filter(bu => {
      return bu.name === this.editBot.businessUnit;
    })[0].id;
    const botDetails = {
      ...this.editBot,
      ...ProjectDetailsRpaModule.getEditBotListingDetails,
    };
    await API.updateBotAndListingDetails(this.projectId, this.botRegistrationId, botDetails)
      .then(() => {
        openSnackbar.call(this, 'Bot was successfully updated');
        ProjectDetailsModule.getProject(this.projectId).then(() => {
          this.backToRpaProjectPage();
        });
      })
      .catch(e => {
        openSnackbar.call(this, e.message, { type: 'error' });
      });
  }

  get editBot(): Projects.UpdateProjectBot {
    const {
      botName,
      riskPartner,
      businessUnit,
      deliveredFor,
      businessOwner,
      botDescription,
      businessProcess,
      configurationId,
      systemIntegrations,
      configurationIdName,
      isOnCyberArk,
      snowData,
    } = ProjectDetailsRpaModule.getEditBotDetails;
    return {
      botName,
      riskPartner,
      businessUnit,
      deliveredFor,
      businessOwner,
      botDescription,
      businessProcess,
      configurationId,
      systemIntegrations,
      configurationIdName,
      isOnCyberArk,
      snowData,
    };
  }

  backToRpaProjectPage(): void {
    this.$router.replace({
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
      },
    });
  }

  @Watch('activeTab.key')
  scrollTop() {
    const elem = document.querySelector('#fs-layout-main');
    if (elem) {
      elem.scrollTop = 0;
    }
  }

}
