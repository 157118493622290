
import { Component, Vue, Prop } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';
import { randId } from '@/utils/components';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  components: {
    GButton,
  },
})
export default class FileSelect extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ default: '', type: String }) readonly accept!: string;
  @Prop({ default: 'text', type: String }) readonly type!: string;
  private id = randId();

  reclick() {
    const l = this.$refs.label as HTMLLabelElement;
    if (l && l.click) {
      l.click();
    }
  }

  public loadTextFromFile(ev: HTMLInputEvent): void {
    if (!ev.target.files) {
      return;
    }
    const file = ev.target.files[0];
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    reader.onload = function (e: any): void {
      if (e.target) {
        vm.$emit('load', e.target.result || '');
      }
    };
    reader.readAsText(file);
  }
}
