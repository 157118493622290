
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputChips } from '@gsk-tech/gsk-input-chips/gsk-input-chips';
import { DeepReadonly } from 'ts-essentials';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import GAnalytics from '@/components/GAnalytics';
import HelpTooltip from '@/components/HelpTooltip.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { BaseUser } from '@/types/users.types';
import { SelectOption } from '@/components/form/form.types';
import { AppEnumsBase } from '@/types/enum.types';
import { BusinessCompliant } from '@/Rpa/types/assessment.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { integrationOptions } from '@/Rpa/constants';
import CiFields from './CiFields.vue';

const OTHER = 'Other';
const YES = 'Yes';
const NO = 'No';

@Component({
  components: {
    GTextfield,
    GPeoplePicker,
    GskMultiSelect,
    GSelect,
    GAnalytics,
    HelpTooltip,
    GRadioGroup,
    GButton,
    CiFields,
    GCheckboxGroup,
  },
})
export default class BotDetailsForm extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: String, default: '' }) readonly botName!: string;
  @Prop({ type: String, default: '' }) readonly botDescription!: string;
  @Prop({ type: Array, default: () => [] }) readonly systemIntegrations!: string[];
  @Prop({ type: String, default: '' }) readonly businessProcess!: string[];
  @Prop({ type: Object }) readonly businessOwner!: BaseUser;
  @Prop({ type: String, default: '' }) readonly businessUnit!: string;
  @Prop({ type: Object }) readonly riskPartner!: BaseUser;
  @Prop({ type: String, default: '' }) readonly deliveredFor!: string;
  @Prop({ type: String, default: '' }) readonly isOnCyberArk!: string;
  @Prop({ type: String, default: '' }) readonly isPrivate!: string;
  @Prop({ type: String, default: '' }) readonly configurationId!: string;
  @Prop({ type: String, default: '' }) readonly configurationIdName!: string;
  @Prop({ type: String, default: '' }) readonly infoClassification!: string;
  @Prop({ type: String, default: '' }) readonly pType!: string;
  @Prop({ type: String, default: '' }) readonly gxp!: string;
  @Prop({ type: String, default: '' }) readonly sox!: string;
  @Prop({ type: String, required: false }) readonly keywords!: string[];

  protected hasOther = false;
  protected YES: string = YES;
  protected NO: string = NO;
  protected OTHER: string = OTHER;

  get businessOwnerValue(): BaseUser[] {
    return this.businessOwner?.mudId ? [this.businessOwner] : [];
  }

  setBusinessOwner(owners: BaseUser[]) {
    const [owner] = owners;
    this.$emit('update:businessOwner', owner);
  }

  get riskPartnerValue(): BaseUser[] {
    return this.riskPartner?.mudId ? [this.riskPartner] : [];
  }

  setRiskPartner(partners: BaseUser[]): void {
    const [partner] = partners;
    this.$emit('update:riskPartner', partner);
  }

  setIsPrivate(input: string): void {
    this.$emit('update:is-private', input);
  }

  publishKeywords(words: string): void {
    this.$emit('updateKeywords', words);
  }

  async updateKeywords(e: CustomEvent): Promise<void> {
    const updated = (e.target as InputChips).updateComplete;
    let i = 0;
    while (!(await updated)) {
      // wait for the updates to complete
      if (++i > 4) {
        // don't wait forever
        break;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keywords: string[] = (e.target as any).chipSet.chips.map(
      (chip: { label: { toLowerCase: () => string } }) => chip.label.toLowerCase().trim(),
    );

    this.$emit('updateKeywords', keywords);
  }

  get businessUnitOptions(): SelectOption[] {
    const businessUnits: DeepReadonly<AppEnumsBase['businessUnit']> =
      EnumsModule.enumsLists.businessUnit;
    return businessUnits.map(bu => {
      return {
        value: bu.name,
      };
    });
  }

  get isOnCyberArkOptions(): BusinessCompliant[] {
    return [
      {
        value: YES,
        checked: this.isOnCyberArk === YES,
      },
      {
        value: NO,
        checked: this.isOnCyberArk === NO,
      },
    ];
  }

  get privacyOptions() {
    return [
      {
        value: '1',
        label: 'Yes',
      },
      {
        value: '0',
        label: 'No',
      },
    ];
  }

  public businessOwnerTooltipText =
    'Business Owner is the entity accountable for the process dimension across functions, delivery locations, and third party providers to ensure an enterprise-wide and truly cross-functional approach to the way services are delivered​​.';

  get integrationOptions(): SelectOption[] {
    return integrationOptions;
  }
}
