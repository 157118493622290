import axios, { getData } from '@/api/service';
import URLs from '@/api/service/urls';

export interface HealthCheckResponse {
  registrationVersionId: number;
  serviceEnvId: number;
  status: 'healthy' | 'unhealthy' | 'na' | 'indeterminate' | 'noenv';
  message: string;
}

export function checkHealth(params: {
  registrationVersionId: number | string;
  serviceEnvId: number | string;
  projectEnvironmentId?: number | string;
}): Promise<HealthCheckResponse> {
  return axios
    .get(
      params?.projectEnvironmentId
        ? URLs.ServiceHealthCheckProjectConnections
        : URLs.ServiceHealthCheck,
      { params },
    )
    .then(getData);
}
