
import { Component, Vue, Emit, Prop, Ref } from 'vue-property-decorator';

// Markdown Editor
import '@toast-ui/editor/dist/toastui-editor.css';
import 'codemirror/lib/codemirror.css';

import FullScreenForm from '@/components/FullScreenForm.vue';
import FileSelect from '@/components/catalog/FileSelect/FileSelect.vue';
import { ListingSectionTemplateTypeIds, ListingSectionTemplateTypes } from '@/constants';
import { ListingSection } from '@/types/listings.types';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    FullScreenForm,
    Editor: () => import('@toast-ui/vue-editor/src/Editor.vue'),
    FileSelect,
    GButton,
  },
})
export default class MarkdownEditor extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly open!: boolean;
  @Prop() text!: string;
  @Prop({ type: Number, required: true }) readonly index!: number;

  @Ref('editor')
  editor!: import('@toast-ui/vue-editor/index').Editor;

  public disabled = true;
  public displayText: string = this.text || '';
  public editorMode = 'wysiwyg';
  public file = '';

  public editorHeight = '';
  public defaultOptions = { hideModeSwitch: false, minHeight: '590px' };

  @Emit('update:open')
  update(isOpen: boolean): boolean {
    if (isOpen) {
      // Need to get the height of the page, because with the markdown editor,
      // you can't just put 'auto' or '100%' in height for some reason. But
      // it does cause the markdown editor to not show until you click into it.
      if (document !== undefined && document.getElementById('app')) {
        this.$nextTick(() => {
          this.editorHeight = (document.getElementById('app')?.clientHeight ?? 96) - 95 + 'px';
        });
      }
    }
    return isOpen;
  }

  public cancel(): void {
    this.update(false);
  }

  public get pageTitle(): string {
    return this.title;
  }

  public close(): void {
    this.update(false);
  }

  public handleFileUpload(md: string) {
    this.editor.invoke('setMarkdown', md);
  }

  public save(): void {
    const listingSection: ListingSection = {
      sectionName: this.title,
      sectionTemplateType: ListingSectionTemplateTypes.Markdown,
      sectionContent: this.editor.invoke('getMarkdown'),
      sectionTemplateTypeId: ListingSectionTemplateTypeIds[ListingSectionTemplateTypes.Markdown],
    };

    this.$emit('save', listingSection);
    this.close();
  }
}
