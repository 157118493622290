
import { Component, Vue, Prop } from 'vue-property-decorator';
import GButton from '../gsk-components/GskButton.vue';
import GSelect from '../gsk-components/GskSelect.vue';
import DraftPreviewWrapper from '@/components/Publish/DraftPreviewWrapper.vue';
import UploadComponent from '@/components/Publish/Upload.vue';
import {
  DocumentationSection,
  DraftListingModule,
  FullListing,
  ListingSectionType,
  ListingTypeTemplate,
} from '@/types/listings.types';
import { ListingTypes } from '@/constants';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { SelectField, TextField } from '@/components/form/form.types';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { ValidationObserver } from 'vee-validate';

type SectionForm = [SelectField<'type'>, TextField<'name'>];

@Component({
  components: {
    GSelect,
    GTextfield,
    GButton,
    UploadComponent,
    DraftPreviewWrapper,
    GDialog,
    ValidatedFormDialog,
  },
})
export default class AddDocumentation extends Vue {
  @Prop(Boolean) readonly isEdit!: boolean;
  @Prop(Boolean) readonly isOptional!: boolean;
  @Prop({ type: Object, required: true }) readonly listingTypeTemplate!: ListingTypeTemplate;
  @Prop({ type: Object, required: true }) readonly draftListing!: DraftListingModule;

  public showPreview = false;

  private openIndex = 0;
  private dialogOpen = false;
  
  open(i: number) {
    this.openIndex = i;
  }
  handleDelete(i: number) {
    // set timeout schedules open after native click event open handler
    setTimeout(() => {
      this.open(Math.max(0, i - 1));
    });

    this.$emit('removeDocumentationSection', i);
  }

  $refs!: {
    docSectionForm: InstanceType<typeof ValidationObserver>;
  };

  docSectionForm: SectionForm = [
    {
      key: 'type',
      type: 'select',
      label: 'Section type',
      enhanced: true,
      value: '',
      // set at created
      options: [],
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      key: 'name',
      type: 'text',
      label: 'Section header',
      value: '',
      required: true,
      validation: {
        rules: {
          required: true,
          max: 40,
        },
      },
      attrs: {
        maxlength: '40',
        'data-testid': 'doc-section-name',
        placeholder: 'Name your section',
      },
    },
  ];

  docSectionType = '';

  // allowed types SelectOption

  setDocSection(payload: { documentationSection: DocumentationSection; index: number }): void {
    this.$emit('setDocumentationSection', payload);
  }

  moveDocSection(payload: { index: number; direction: 'up' | 'down' }): void {
    this.$emit('moveDocumentationSection', payload);
  }

  get content() {
    return this.listingTypeTemplate.publishing.content.documentation;
  }

  public get documentationSectionsMeta() {
    return this.listingTypeTemplate.publishing.documentationSections;
  }

  public get canAddNewSection() {
    const numSections = this.draftListing.extendedProperties.documentationSections.length;
    const maxSections = this.documentationSectionsMeta.maxSections;

    return numSections < maxSections;
  }

  public get currentDraftListing() {
    return this.draftListing;
  }

  public get transformedListing(): FullListing {
    const listing = this.currentDraftListing;
    return {
      ...listing,
      listingId: listing.registrationId,
      iconUrl: '',
      listingTypeName: ListingTypes[listing.listingTypeId],
      keywordList: [],
      owners: listing.listingUsers,
      publishedBy: { mudId: '', email: '' },
      versions: [],
      authenticationType: '',
    };
  }

  openPreview() {
    this.showPreview = true;
  }

  addNewSectionDialog() {
    this.dialogOpen = true;
  }

  resetVerifyForm() {
    for (const field of this.docSectionForm) {
      field.value = '';
    }
  }

  resetVerificationCommentForm() {
   this.resetVerifyForm();
  }

  onDialogClosed() {
    this.resetVerifyForm();
    this.resetVerificationCommentForm();
    this.$nextTick(() => {
      this.$refs.docSectionForm.reset();
    });
  }

  get newSectionMeta(): DocumentationSection['meta'] {
    const ds = this.listingTypeTemplate.publishing.documentationSections;
    return {
      canDelete: true,
      canRename: true,
      canReorder: true,
      canEditContent: ds.canEditContent,
      canLinkContent: ds.canLinkContent,
    };
  }
  addNewSection() {
    this.$emit('addDocumentationSection', {
      sectionInfo: {
        sectionName: this.docSectionForm[1].value,
        sectionContent: '',
        sectionTemplateTypeId: -1,
        sectionTemplateType: this.docSectionForm[0].value,
      },
      meta: this.newSectionMeta,
    });
    
    this.dialogOpen = false;
    this.openIndex = this.documentationSections.length - 1;
    this.onDialogClosed();
  }

  public get documentationSections(): DocumentationSection[] {
    return this.draftListing.extendedProperties.documentationSections;
  }

  showUp(i: number) {
    if (this.documentationSections[i]) {
      if (!this.documentationSections[i].meta.canReorder) {
        return false;
      }
    }
    if (this.showArrows) {
      if (i === 0) {
        return false;
      }
      const prev = this.documentationSections[i - 1];
      if (prev) {
        return prev.meta.canReorder;
      }
      return false;
    }
    return false;
  }

  showDown(i: number) {
    if (this.documentationSections[i]) {
      if (!this.documentationSections[i].meta.canReorder) {
        return false;
      }
    }
    if (this.showArrows) {
      if (i === this.documentationSections.length - 1) {
        return false;
      }
      const next = this.documentationSections[i + 1];
      if (next) {
        return next.meta.canReorder;
      }
      return false;
    }
    return false;
  }

  get showArrows() {
    return this.documentationSections.length > 1;
  }
  created() {
    const lookup: Record<ListingSectionType, string> = {
      markdown: 'Markdown',
      'open-api': 'Open API Spec',
    };
    this.docSectionForm[0].options =
      this.listingTypeTemplate.publishing.documentationSections.allowedSectionTypes.map(type => ({
        label: lookup[type],
        value: type,
      }));
  }
}
