
import { RawLocation } from 'vue-router';
import { Component, Prop } from 'vue-property-decorator';
import { uniqBy, isEmpty } from 'lodash';
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import { mixins } from 'vue-class-component';
import GButton from '@/components/gsk-components/GskButton.vue';
import { ListingTypes, Roles, RouteNames, AUTH_SRC, Platforms, } from '@/constants';
import { FormField } from '@/components/form/form.types';
import { GithubRepo, ListingUser, ListingProps } from '@/types/publishing.types';
import UserList from '@/components/UserList.vue';
import { UserModule } from '@/store/modules/user.module';
import ListingDetailsMixin from '@/components/mixins/listing-details.mixin';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import HelpTooltip from '@/components/HelpTooltip.vue';
import HealthCheck from '@/components/HealthCheck.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import FeatureFlag from '@/components/FeatureFlag';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { FullListing, ListingVersion } from '@/types/listings.types';
import { Environment } from '@/types/enum.types';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import CopyCode from '@/components/CopyCode.vue';
import PlatformTypeChip from '@/components/PlatformTypeChip.vue';
import { sanitize } from '@/utils/components';

function formFieldValueToString(field: FormField): string {
  if (field.type === 'github-repo') {
    if (field.value) {
      return field.value.html_url;
    }
    return '';
  }
  if (field.type === 'people-picker') {
    return field.value.map(u => u.email).join(', ');
  }
  if (Array.isArray(field.value)) {
    return field.value.join(', ');
  }
  if (field.type === 'number') {
    return (field.value || 0).toString();
  }

  return field.value.toString();
}

type GskMenu = Menu;

const DEMO_LINK_KEY = 'demo-link';

const createMenuOption = (key: string) => ({ key, text: key });
@Component({
  components: {
    FeatureFlag,
    GButton,
    UserList,
    IconMenu,
    GAnalytics,
    HelpTooltip,
    HealthCheck,
    GDialog,
    CopyCode,
    PlatformTypeChip,
  },
  metaInfo() {
    return {
      title: ` - ${(this as ListingDetails).pageTitle}`,
    };
  },
})
export default class ListingDetails extends mixins(ListingDetailsMixin) {
  @Prop(Boolean) draft!: boolean;
  @Prop() listingDetail!: FullListing;
  public openUnavailableDialog = false;
  public windowWidth: any = window.innerWidth;
  public sanitizeLink = (source: any) => sanitize(source);
  get authType(): string {
    const authConf = Object.keys(AUTH_SRC).find(
      item => AUTH_SRC[item].apiRegistrationEnum === this.listing?.authenticationType,
    );

    return authConf ? AUTH_SRC[authConf].readableName : '';
  }

  private get pageTitle(): string {
    return this.listing.listingName;
  }

  get editName(): string {
    const { botRegistrationId } = this.listing;
    return this.isRpa && botRegistrationId ? 'bot' : 'listing';
  }

  get healthCheckData(): { rvId: number; envId: number }[] {
    return EnumsModule.enumsLists.apiEnvironment
      .map(en => ({
        envId: en.id,
        envName: en.name,
        rvId: this.listing.registrationVersionId || 0,
      }))
      .filter(hc => !!hc.rvId);
  }

  get connectAnalytics(): ClickData {
    return {
      clickTarget: 'listing-details-connect-button',
      listingName: this.listing.listingName,
      listingType: 'API',
    };
  }

  get isOwner(): boolean {
    const u = UserModule.user.mudId;
    const { role } = EnumsModule.enums;
    const isDirectOwner = !!this.listing.listingUsers?.find(o => o.mudId === u && (o.roleId === role.OWNER.id  || o.isEditPrivilege === true));
    if (isDirectOwner) {
      return true;
    } else {
      return !!this.listing.teams.find(t => {
        return t.teamMembers.some(m => m.mudId == u && (t.roleId === role.OWNER.id  || m.isEditPrivilege === true));
      });
    }
  }

  get displayOwners(): ListingUser[] {
    let allMembers: ListingUser[] = [];
    if (this.listing.listingUsers) {
      allMembers = [...this.listing.listingUsers.filter(u => u.roleId === Roles.Owner)];
    }
    this.listing.teams?.map(team => {
      if (team.roleId === Roles.Owner) {
        team.teamMembers?.map(member => {
          if (!allMembers.find((r: ListingUser) => member.mudId == r.mudId)) {
            allMembers.push({ ...member });
          }
        });
      }
    });
    return allMembers;
  }

  get editLink(): RawLocation {
    const { listingId, versions } = this.listing;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      listingId: (listingId || '').toString(),
    };
    if (versions && versions.length > 0) {
      const currentSelectedVersion = versions.filter((obj) => {
        return obj.listingVersionId.toString() === String(this.queryVersion);
      });
      if(currentSelectedVersion[0])
        params.listingVersionId = (currentSelectedVersion[0].listingVersionId || '').toString();
    }
    const { botRegistrationId, sourceProjectId } = this.listing;
    if (this.isRpa && botRegistrationId) {
      return {
        name: RouteNames.EditRpaBot,
        params: {
          botRegistrationId: String(botRegistrationId),
          projectId: String(sourceProjectId),
          envId: Environment.RPA_DEV,
        },
      };
    } else {
      return {
        name: RouteNames.PublishListing,
        params,
        query: { mode: 'edit' },
      };
    }
  }

  setNewVersion(version: string) {
    if (this.queryVersion !== version) {
      // the check avoids a duplicate navigation error
      this.$router
        .replace({
          name: RouteNames.ListingSection,
          params: this.$route.params,
          query: {
            version: version,
          },
        })
        .catch(e => this.$log(e));
    }
  }

  get versionOptions(): MenuOption[] {
    if (this.l.versions?.length) {
      let listingVersion: ListingVersion[] = uniqBy(this.l.versions, listing => listing.versionId);
      return listingVersion.map(v => {
        return {
          key: v.listingVersionId.toString(),
          text: 'v' + v.versionId,
        };
      });
    }
    const repo = this.l.extendedProperties.githubRepo;
    if (!repo) {
      return [];
    }
    if (!repo.releaseTags) {
      return [createMenuOption(repo.default_branch)];
    }
    return [repo.default_branch].concat(repo.releaseTags).map(createMenuOption);
  }

  get isSystemVersioned(): boolean {
    return !!this.l.versions.length;
  }

  get isGithubVersioned(): boolean {
    return !!this.l.extendedProperties.githubRepo;
  }

  get queryVersion(): string | null {
    const version = this.$route.query.version;
    let v = null;
    if (Array.isArray(version)) {
      v = version[0] || '';
    } else if (version) {
      v = version;
    }
    return v;
  }

  get currentVersion() {
    // just fixing type errors, hopefully this really works
    const l = this.l;
    if (this.isApi && l.versionId) {
      return `v${l.versionId}`;
    } else if (this.selectedVersionOption) {
      return this.selectedVersionOption.text;
    }
    return '';
  }

  get selectedVersionOption() {
    const v = this.queryVersion;
    return (
      this.versionOptions.find(version => version.key === v) ||
      // if it's github versioned this will look at vOpts[1] in order to skip the
      // "default branch" that we add to the list of release tags
      this.versionOptions[Number(this.isGithubVersioned)] ||
      this.versionOptions[0]
    );
  }

  get isPlatformEnabled() {
    return FeatureFlagsModule.platformEnabled || false;
  }
  selectedVersion(): string {
    const v = this.selectedVersionOption;
    if (v && !this.draft) {
      this.setNewVersion(v.key);
    }
    return v.text;
  }

  get isCicd(): boolean {
    if(EnumsModule.enums.listingType.CICD)
      return this.listing.listingTypeId === EnumsModule.enums.listingType.CICD.id;
      
    return false;
  }

  get isApi(): boolean {
    return this.listing.listingTypeId === ListingTypes.API;
  }

  get isRpa(): boolean {
    return this.listing.listingTypeId === ListingTypes.RPA;
  }

  get isRepoType(): boolean {
    return this.listingTypeId === ListingTypes.GithubRepo;
  }

  get l() {
    return this.listing;
  }

  get repo(): GithubRepo | null {
    return this.listing.extendedProperties.githubRepo;
  }

  get rpaListingDetails(): ListingProps[] | null {
    return this.listing.extendedProperties.details;
  }

  get repoLink() {
    return this.repo ? this.repo.html_url : '';
  }

  get demoLinkField() {
    const demoLink = this.l.extendedProperties.details.find(field => field.key === DEMO_LINK_KEY);
    if (demoLink) {
      return this.formFieldsToDetails(demoLink);
    }
    return undefined;
  }
  get details() {
    return this.listing.extendedProperties.details
      .filter(field => field.key !== DEMO_LINK_KEY)
      .map(this.formFieldsToDetails)
      .filter(field => !!field.value);
  }

  versionCompare(v1: string, v2: string) {
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isPositiveInteger(x: any) {
      // http://stackoverflow.com/a/1019526/11236
      return /^\d+$/.test(x);
    }

    // First, validate both numbers are true version numbers
    function validateParts(parts: any[]) {
      for(const value of parts) {
        if (!isPositiveInteger(value)) {
          return false;
        }
      }
      return true;
    }
    if (!validateParts(v1parts) || !validateParts(v2parts)) {
      return NaN;
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      }
      if (v1parts[i] > v2parts[i]) {
        return 1;
      }
      return -1;
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }

  formFieldsToDetails(field: FormField) {
    if (field.type === 'people-picker') {
      return {
        type: field.type,
        label: field.outputLabel || field.label,
        value: field.value.length ? field.value : false,
      };
    }
    return {
      type: field.type,
      label: field.outputLabel || field.label,
      value: formFieldValueToString(field),
    };
  }

  get connectCicdLink(): RawLocation {
    return {
      name: RouteNames.ListingConnectCicd,
      params: {
        listingId: this.listing?.listingId?.toString() ?? '',
      },
    };
  }

  get connectLink(): RawLocation {
    return {
      name: RouteNames.ListingConnect,
      params: addAnalyticsRouteParams(
        {
          listingId: (this.listing.listingId || -1).toString(),
        },
        {
          listingName: this.listing.listingName,
          listingType: this.listing.listingTypeName,
        },
      ),
    };
  }

  isPeople(type: FormField['type']): boolean {
    return type === 'people-picker';
  }

  transformLinks(text: string): string {
    return DOMPurify.sanitize(
      anchorme(text, {
        attributes: [
          {
            name: 'target',
            value: '_blank',
          },
        ],
        // truncate: 50,
      }),
      {
        ADD_ATTR: ['target'],
      },
    );
  }

  isHealthCheckEnabled(envId: number) {
    const env = this.getEnv(envId);
    return env? !(isEmpty(env?.healthCheckPath) || isEmpty(env?.healthCheckPattern)): false;
  }

  getEnv(envId: number) {
    return this.listing?.healthCheck?.find((i: any) => i.environmentId === envId);
  }

  get kongDisabled(): boolean {
    return !FeatureFlagsModule.kongEnabled;
  }

  showUnavailableDialog(): void {
    this.openUnavailableDialog = true;
  }
  get maintenanceModeDev(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledDev;
  }
  get maintenanceModeProd(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledProd;
  }
  get maintenanceModeMessage() {
    return ProcessEnvsModule.processEnvs.maintenanceModeMessage;
  }
  get tooltipPosition() {
    return this.windowWidth <= 1440? 'after': 'below';
  }
  get ownersText () { 
    return this.displayOwners.map(member =>  `${member.fullName} (${member.mudId})`).join(', ');
  }

  get platform() {
    const mnemonic =  this.listingDetail?.platform?.mnemonic;
    return mnemonic? Platforms[mnemonic]?.readableName: undefined;
  }
}
