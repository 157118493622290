
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import MarkdownEditor from '@/components/Publish/MarkdownEditor.vue';
import LinkEditor from '@/components/Publish/LinkEditor.vue';
import OpenApiSpecLink from '@/components/Publish/OpenApiSpecLink.vue';
import {
  DocumentationSection,
  DraftListingModule,
  ListingSection,
  ListingSectionOptions,
  ListingTypeTemplate,
  APIListingSectionOptions
} from '@/types/listings.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GSwitch from '@/components/gsk-components/GskSwitch.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import { ListingSectionTemplateTypes } from '@/constants';
import RepoPicker from '@/components/RepoPicker.vue';
import GithubRepoLink from '@/components/Publish/GithubRepoLink.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';

@Component({
  components: {
    MarkdownEditor,
    LinkEditor,
    OpenApiSpecLink,
    GButton,
    GDialog,
    GTextfield,
    GSwitch,
    DeleteDialog,
    RepoPicker,
    GithubRepoLink,
  },
})
export default class UploadComponent extends Vue {
  @Prop({ type: Object, required: true }) readonly listingTypeTemplate!: ListingTypeTemplate;
  @Prop({ type: Object, required: true }) readonly draftListing!: DraftListingModule;
  @Prop({ type: Object, required: true }) section!: DocumentationSection;
  @Prop(Boolean) open!: boolean;
  @Prop(Boolean) showArrows!: boolean;
  @Prop(Boolean) showUp!: boolean;
  @Prop(Boolean) showDown!: boolean;
  @Prop(Boolean) isEdit!: boolean;
  @Prop(Boolean) isOptional!: boolean;
  @Prop({ type: Number, required: true }) readonly index!: number;

  private dialogOpen = false;
  private deleteOpen = false;
  private modalVal = '';
  public initialMarkdown = `# An h1 header
2nd paragraph. *Italic*, **bold**, and \`monospace\`. Itemized lists
look like:

* this one
* that one
* the other one`;
  public showEdit = false;
  public showLink = false;
  public isLocal = true;
  private githubDialogOpen = false;
  private sectionOptionsModel: ListingSectionOptions = {};

  showMenu() {
    const menu = this.$refs.settingsMenu as Menu;
    menu.setAnchorCorner(menu.Corner.BOTTOM_START);
    menu.setAnchorElement(this.$refs.menuContainer as Element);
    menu.setAnchorMargin({ bottom: 16 });
    menu.open = !menu.open;
  }

  created() {
    this.modalVal = this.section.sectionInfo.sectionName;
    if (this.done) {
      this.isLocal = !this.link;
    } else {
      this.isLocal = this.canEditContent;
    }

    const { sectionInfo } = this.section;
    if (sectionInfo.sectionTemplateType === 'open-api') {
      const sectionOptions = sectionInfo?.sectionOptions as APIListingSectionOptions;
      if (FeatureFlagsModule.tryitEnabled) {
        this.sectionOptionsModel.tryItEnabled = !!sectionOptions?.tryItEnabled;
      }
    }
  }

  get sectionOptions(): string[] {
    return Object.keys(this.sectionOptionsModel);
  }

  get sectionInfo() {
    return this.section.sectionInfo;
  }

  get forceGithub(): boolean {
    return this.listingTypeTemplate.publishing.githubRepo.canLink;
  }

  get githubLinked(): boolean {
    return !!this.draftListing.extendedProperties.githubRepo;
  }

  public get documentationSectionsMeta() {
    return this.listingTypeTemplate.publishing.documentationSections;
  }

  get meta() {
    return this.section.meta;
  }

  get canEditContent(): boolean {
    // default section metadata can override overall template settings
    return this.documentationSectionsMeta.canEditContent && this.meta.canEditContent;
  }

  get canLinkContent(): boolean {
    return this.documentationSectionsMeta.canLinkContent && this.meta.canLinkContent;
  }

  get isOpenApi() {
    return this.sectionInfo.sectionTemplateType === ListingSectionTemplateTypes.OpenApi;
  }

  get isMarkdown() {
    return this.sectionInfo.sectionTemplateType === ListingSectionTemplateTypes.Markdown;
  }

  get done() {
    return !!this.sectionInfo.sectionContent || !!this.sectionInfo.sectionContentUrl;
  }

  get editorText() {
    if (!this.isEdit) {
      return this.sectionInfo.sectionContent || this.initialMarkdown;
    }
    return this.sectionInfo.sectionContent || '';
  }

  get link() {
    return this.sectionInfo.sectionContentUrl || '';
  }

  editClick(): void {
    this.dialogOpen = true;
  }

  set sectionOptionsTryItEnabled(value: boolean) {
    (this.sectionOptionsModel as APIListingSectionOptions).tryItEnabled = value;
    this.setDocumentationSection({
      documentationSection: {
        sectionInfo: {
          ...this.section.sectionInfo,
          sectionOptions: this.sectionOptionsModel,
        },
        meta: this.meta,
      },
      index: this.index,
    });
  }

  get sectionOptionsTryItEnabled(): boolean {
    const options = this.sectionOptionsModel as APIListingSectionOptions;
    return options.tryItEnabled || false;
  }

  setDocumentationSection(payload: {
    documentationSection: DocumentationSection;
    index: number;
  }): void {
    this.$emit('setDocumentationSection', payload);
  }

  editorSave(listingSection: ListingSection): void {
    this.setDocumentationSection({
      documentationSection: {
        sectionInfo: {
          ...listingSection,
          sectionOptions: this.sectionOptionsModel,
        },
        meta: this.meta,
      },
      index: this.index,
    });
  }

  handleDialogOpen(isOpen: boolean): void {
    this.modalVal = this.sectionInfo.sectionName;
    this.dialogOpen = isOpen;
  }

  editName(): void {
    if (!this.meta.canRename) {
      return;
    }
    if (this.modalVal.trim() === '') {
      return;
    }
    const documentationSection: DocumentationSection = {
      ...this.section,
      sectionInfo: {
        ...this.section.sectionInfo,
        sectionName: this.modalVal,
        sectionOptions: this.sectionOptionsModel,
      },
    };
    this.setDocumentationSection({
      documentationSection,
      index: this.index
    });
    this.dialogOpen = false;
  }

  clearSectionContent(): void {
    const documentationSection: DocumentationSection = {
      ...this.section,
      sectionInfo: {
        ...this.section.sectionInfo,
        sectionContent: '',
        sectionOptions: this.sectionOptionsModel,
      },
    };
    delete documentationSection.sectionInfo.sectionContentUrl;

    this.setDocumentationSection({
      documentationSection,
      index: this.index,
    });
  }

  deleteSection(): void {
    this.$emit('delete', this.index);
  }

  public clickedEdit(): void {
    this.showLink = false;
    this.showEdit = true;
  }

  public clickedLink(): void {
    if (this.forceGithub && !this.githubLinked) {
      this.githubDialogOpen = true;
    } else {
      this.showLink = true;
      this.showEdit = false;
    }
  }

  get uploadText(): string {
    return this.done ? 'Edit' : 'Add';
  }

  moveDocumentationSection(payload: { index: number; direction: 'up' | 'down' }): void {
    this.$emit('moveDocumentationSection', payload);
  }

  up(): void {
    this.moveDocumentationSection({
      index: this.index,
      direction: 'up',
    });
  }

  down(): void {
    this.moveDocumentationSection({
      index: this.index,
      direction: 'down',
    });
  }
}
