
import { Component, Vue } from 'vue-property-decorator';
import { PublishingModule } from '@/store/modules/publishing.module';
import { GithubRepo } from '@/types/publishing.types';
import RepoPicker from '@/components/RepoPicker.vue';

@Component({
  components: {
    RepoPicker,
  },
})
export default class GithubRepoLink extends Vue {
  get repo() {
    return PublishingModule.draftListing.extendedProperties.githubRepo;
  }

  set repo(repo: GithubRepo | null) {
    PublishingModule.linkGithubRepo(repo);
  }

  get disabled() {
    return false;
  }

  get repoInfo() {
    return PublishingModule.listingTypeTemplate.publishing.githubRepo;
  }
}
