
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import {
  DocumentationSection,
  DraftListingModule,
  ListingTypeTemplate,
} from '@/types/listings.types';
import AddDocumentation from '@/components/Publish/AddDocumentation.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import { RouteNames } from '@/constants';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    AddDocumentation,
    Grid,
    GridCell,
    GButton,
  },
})
export default class RpaDocumentation extends Vue {
  @Prop(Boolean) readonly isEdit!: boolean;
  @Prop(Boolean) readonly isOptional!: boolean;

  public get rpaDraftListing(): DraftListingModule {
    if (this.isEdit) {
      return ProjectDetailsRpaModule.getEditBotListingDetails;
    } else {
      return ProjectDetailsRpaModule.rpaDraftListing;
    }
  }

  public get rpaListingTypeTemplate(): ListingTypeTemplate {
    return ProjectDetailsRpaModule.rpaListingType[0].listingTemplate;
  }

  addRpaDocSection(section: DocumentationSection): void {
    if (this.isEdit) {
      let { documentationSections } = cloneDeep(ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties);
      documentationSections.push(section)
      const extendedProperties = {
        ...ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties,
        ...{'documentationSections': documentationSections},
      };
      ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'extendedProperties', value: extendedProperties});
    } else {
      ProjectDetailsRpaModule.addRpaDocumentationSection(section);
    }
  }

  setRpaDocSection(payload: { documentationSection: DocumentationSection; index: number }): void {
    if (this.isEdit) {
      let { documentationSections } = cloneDeep(ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties);
      documentationSections[payload.index] = payload.documentationSection;
      const extendedProperties = {
        ...ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties,
        ...{'documentationSections': documentationSections},
      };
    ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'extendedProperties', value: extendedProperties});
    } else {
      ProjectDetailsRpaModule.setRpaDocumentationSection(payload);
    }
  }

  removeRpaDocSection(index: number): void {
    if (this.isEdit) {
      let { documentationSections } = cloneDeep(ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties);
      documentationSections = documentationSections.filter(
        (section, i) => {
          return i !== index;
        },
      );
      const extendedProperties = {
        ...ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties,
        ...{'documentationSections': documentationSections},
      };
      ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'extendedProperties', value: extendedProperties});
    } else {
      ProjectDetailsRpaModule.removeRpaDocumentationSection(index);
    }
  }

  moveRpaDocSection(payload: { index: number; direction: 'up' | 'down' }): void {
    if (this.isEdit) {
      const { direction, index: i } = payload;
      const lastIndex = ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties.documentationSections.length - 1;
      // up being smaller index, but it sounded right...
      const isUp = direction === 'up';
      if ((isUp && i < 1) || (!isUp && i >= lastIndex)) {
        // out of bounds
        return;
      }
      let targetIndex = i;
      isUp ? targetIndex-- : targetIndex++;
      const { documentationSections } = cloneDeep(ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties);
      const toMove = documentationSections[payload.index];
      const swapTarget = documentationSections[targetIndex];
      if (toMove.meta.canReorder && swapTarget.meta.canReorder) {
        documentationSections[i] = swapTarget;
        documentationSections[targetIndex] = toMove;
        const extendedProperties = {
          ...ProjectDetailsRpaModule.getEditBotListingDetails.extendedProperties,
          ...{'documentationSections': documentationSections},
        };
        ProjectDetailsRpaModule.setEditBotListingDetailsKeyVal({key: 'extendedProperties', value: extendedProperties});
      }
    } else {
      ProjectDetailsRpaModule.moveRpaDocumentationSection(payload);
    }
  }

  submit(): void {
    this.$emit('submit');
  }

  save(): void {
    this.$emit('save', ProjectDetailsRpaModule.getEditBotListingDetails);
  }

  backToRpaProjectPage(): void {
    this.$router.replace({
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
      },
    });
  }

  get projectId() {
    return this.$route.params.projectId;
  }

  get envId() {
    return this.$route.params.envId;
  }
}
