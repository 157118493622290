import { Component, Prop, Vue } from 'vue-property-decorator';
import { ListingTypes } from '@/constants';
import { ListingCardListing } from '@/types/listings.types';
import { ListingsModule } from '@/store/modules/listings.module';

@Component
export default class ListingDetailsMixin extends Vue {
  @Prop({ type: Object, required: true }) public readonly listing!: ListingCardListing;

  public get isMobileComp(): boolean {
    return this.listing.listingTypeId === ListingTypes.MobileComponent;
  }

  public get isWebComp(): boolean {
    return this.listing.listingTypeId === ListingTypes.WebComponent;
  }

  public get icon(): string {
    const i = ListingsModule.listingIconTypes.filter(lt => lt.listingTypeId === this.listingTypeId);
    if (i.length == 0) {
      return '';
    }
    const img = i[0].defaultIcon;
    if (img) {
      return img;
    }
    return `/img/listing-types/${this.listingTypeId}.svg`;
  }

  public get listingTypeId() {
    return this.listing.listingTypeId;
  }

  public get libs(): string {
    if (!this.listing.extendedProperties && !this.listing?.details) {
      return '';
    }
    const deets = this.listing.extendedProperties?.details || this.listing?.details;
    const lib = deets.find(field => field.key === 'library' || field.key === 'framework');
    if (lib) {
      if (Array.isArray(lib.value)) {
        return lib.value.join(', ');
      }
      if (typeof lib.value === 'string') {
        return lib.value;
      }
    }
    return '';
  }

  public get isApi(): boolean {
    return this.listing.listingTypeId === ListingTypes.API;
  }
}
