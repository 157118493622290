
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { checkHealth, HealthCheckResponse } from '@/api/health-check.api';
import { randId } from '@/utils/components';

@Component({
  components: {
    Promised,
  },
})
export default class HealthCheck extends Vue {
  @Prop({ type: [String, Number], required: true })
  registrationVersionId!: string | number;
  @Prop({ type: [String, Number], required: true })
  environmentId!: string | number;
  @Prop({ type: Boolean, default: false }) smallTooltip!: boolean;

  @Prop({ type: Boolean, default: false, required: false }) isHealthCheckEnabled!: boolean;
  @Prop({ type: [String, Number], required: false })
  projectEnvironmentId!: string | number;

  healthPromise: Promise<HealthCheckResponse> | null = null;

  get statusMap(): Record<HealthCheckResponse['status'], string> {
    return {
      healthy: 'check_filled',
      unhealthy: 'warning__circle_filled',
      indeterminate: 'warning__circle_filled',
      na: 'close_filled',
      noenv: 'close_filled',
    };
  }
  id = randId();

  type(res: HealthCheckResponse): string {
    return this.statusMap[res.status];
  }

  load() {
    this.healthPromise = checkHealth({
      registrationVersionId: this.registrationVersionId,
      serviceEnvId: this.environmentId,
      ...((this.projectEnvironmentId ? true : false) && { "projectEnvironmentId": this.projectEnvironmentId })
    });
  }

  mounted() {
    if(this.isHealthCheckEnabled) {
      this.load();
    }
  }
}
