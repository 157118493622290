
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import ListingDetail from '@/views/ListingDetail.vue';
import { FullListing, ListingUser } from '@/types/listings.types';
import { Roles, RoleNames } from '@/constants';
import { PublishingModule } from '@/store/modules/publishing.module';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    ListingDetail,
    GButton,
  },
})
export default class DraftPreviewWrapper extends Vue {
  @Prop(Boolean) readonly open!: boolean;
  @Prop() readonly listing!: FullListing;
  @Prop(Boolean) readonly responseLoading!: boolean;

  public editorMode = 'preview';
  public file: string | null = null;

  public editorHeight = '';
  public defaultOptions: { hideModeSwitch: boolean } = { hideModeSwitch: false };

  @Emit('update:open')
  public update(isOpen: boolean): boolean {
    if (isOpen) {
      // Need to get the height of the page, because with the preview editor,
      // you can't just put 'auto' or '100%' in height for some reason.
      if (document !== undefined && document.getElementById('app')) {
        this.$nextTick(() => {
          this.editorHeight = (document.getElementById('app')?.clientHeight ?? 91) - 90 + 'px';
        });
      }
    }
    return isOpen;
  }

  public cancel(): void {
    this.update(false);
  }

  @Emit()
  public close(): void {
    this.update(false);
  }

  public get currentDraftListing(): FullListing {
    return this.listing;
  }

  public get disabled() {
    return !PublishingModule.publishEnabled;
  }

  get getOwners(): ListingUser[] {
    return this.currentDraftListing.listingUsers
      ? this.currentDraftListing.listingUsers
      : this.currentDraftListing.owners.map(user => {
          return {
            ...user,
            roleId: user.roleId ? user.roleId : Roles.Owner,
            roleName: RoleNames.Owner,
          };
        });
  }
}
