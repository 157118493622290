
import { addAnalyticsRouteParams, analyticsClick, ClickData } from '@/analytics';
import * as API from '@/api/github.api';
import { getConnectedProjects } from '@/api/listings.api';
import axios from '@/api/service';
import URLs, { getProxyUrl, safeUrl } from '@/api/service/urls';
import GAnalytics from '@/components/GAnalytics';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
import { ListingSectionTemplateTypes, RouteNames } from '@/constants';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ListingsModule } from '@/store/modules/listings.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import {
  APIListingSectionOptions,
  FullListing,
  ListingConnectedProject,
  ListingConnectedProjectTargetService,
  ListingSection
} from '@/types/listings.types';
import {
  marked,
  replaceLinkedRepoRelativeLinks,
  replaceLinkedUrlRelativeLinks
} from '@/utils/markdown';
import { slugify } from '@/utils/routing';
import { Promised } from 'vue-promised';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import * as Yaml from 'yaml';
import { openErrorSnackbar } from '@/utils/components';
import { ProjectEnvironment } from '@/types/projects.types';
import { Platform } from '@/types/enum.types';

@Component({
  name: 'ListingDetailInfo',
  components: {
    GButton,
    GSelect,
    NavigationTabs,
    Promised,
    Grid,
    GridCell,
    GAnalytics,
  },
})
export default class ListingDetailInfoView extends Vue {
  @Prop() listing!: FullListing;
  @Prop() activeTab!: ListingSection;

  public isDraft = false;
  public listingSections: ListingSection[] = [
    {
      sectionName: '',
      sectionTemplateType: '',
      sectionTemplateTypeId: 1,
      sectionContent: '',
    },
  ];
  public markdown = '';
  public listingSwaggerDoc = '';
  public repoName = '';
  public contentPath = '';
  public renderedMarkdown = '';
  public isToggled = true;
  public connectedProjects: ListingConnectedProject[] = [];
  public referrerProjectId?: number;
  private destroyRouterBeforeEach = () => {};
  public specLoadError = false;

  get hasContent() {
    return !!this.section.sectionContent || !!this.section.sectionContentUrl;
  }
  get noContent() {
    return !this.hasContent;
  }
  get showMarkdown() {
    return (
      this.hasContent && this.section.sectionTemplateType === ListingSectionTemplateTypes.Markdown
    );
  }
  get showOpenApi() {
    return (
      this.hasContent && this.section.sectionTemplateType === ListingSectionTemplateTypes.OpenApi
    );
  }

  get apiServerBaseUrl(): string | null {
    const env = this.tryItServiceEnvironment.toUpperCase();
    const serverUrl = ProcessEnvsModule.getApiGatewayUrlMapping.get(env);
    if (serverUrl) {
      return serverUrl.replace(/http?s:\/\//, '');
    }
    return '[Please select a service environment]';
  }

  get connectedProjectNamesOptions() {
    return this.connectedProjects.map((project) => ({
      key: project.projectId,
      value: project.projectName,
    }));
  }

  get projectEnvironmentOptions() {
    const project = this.connectedProjects.find(
      project => project.projectId === this.tryItProjectId,
    );
    if (project) {
      return project.environments.map(env => ({
        key: env.projectEnvironmentId,
        value: env.mnemonic,
        env,
      }));
    }
    return [];
  }

  get serviceEnvironmentOptions() {
    const projectEnvironment = this.projectEnvironmentOptions.find(
      env => env.key === this.tryItProjectEnvironmentId,
    );
    if (projectEnvironment) {
      return projectEnvironment.env.targetServices.map(service => ({
        key: service.serviceId,
        value: service.environmentName.toUpperCase(),
        envId: service.environmentId,
        service,
      }));
    }
    return [];
  }

  get selectedServiceProxyPath(): string {
    const projectEnvironment = this.projectEnvironmentOptions.find(
      env => env.key === this.tryItProjectEnvironmentId,
    );
    if (projectEnvironment) {
      const service = projectEnvironment.env.targetServices
        .find(service => this.tryItServiceId === service.serviceId);
      if (service) {
        const [proxyPath] = service.kongProxyPath;
        return String(proxyPath);
      }
      return '';
    }
    return '';
  }

  get tryItProjectId(): number {
    return ListingsModule.tryItSelectedParams.projectId;
  }

  get tryItProjectEnvironmentId(): number {
    return ListingsModule.tryItSelectedParams.projectEnvironmentId;
  }

  get tryItServiceId(): number {
    return ListingsModule.tryItSelectedParams.serviceId;
  }

  get tryItProjectName(): string {
    const projectOption = this.connectedProjectNamesOptions.find(
      opt => opt.key === this.tryItProjectId,
    );
    return projectOption ? projectOption.value : '';
  }

  set tryItProjectName(projectName: string) {
    const projectOption = this.connectedProjectNamesOptions.find(
      opt => opt.value === projectName,
    );

    const projectEnvironment = this.connectedProjects
      .find((project) => project.projectId === projectOption?.key);

    let environment!: Partial<ProjectEnvironment> & { targetServices: ListingConnectedProjectTargetService[] },
        service!: ListingConnectedProjectTargetService,
        selectedEnvironment = -1,
        selectedService = -1;

    if (projectEnvironment?.environments.length) {
      [environment] = projectEnvironment?.environments;
    }

    if (environment) {
      selectedEnvironment = environment.projectEnvironmentId || -1;
      [service] = environment.targetServices;
    }

    if (service) {
      selectedService = service.serviceId || -1;
    }

    ListingsModule.setTryItParam({
      listingId: Number(this.currentListing.listingId),
      params: {
        projectId: Number(projectOption?.key),
        projectEnvironmentId: selectedEnvironment,
        serviceId: selectedService,
      },
    });
  }

  get tryItProjectEnvironment(): string {
    const projectEnvironmentOption = this.projectEnvironmentOptions.find(
      env => env.key === this.tryItProjectEnvironmentId,
    );
    return projectEnvironmentOption ? String(projectEnvironmentOption.value) : '';
  }

  set tryItProjectEnvironment(value: string) {
    const projectEnvironmentId = this.projectEnvironmentOptions.find(
      env => env.value === value,
    );

    const selectedProject = this.connectedProjects
      .find((project) => project.projectId === this.tryItProjectId);

    const environment = selectedProject?.environments
      .find((env) => env.projectEnvironmentId === projectEnvironmentId?.key);

    const [service] = environment?.targetServices as ListingConnectedProjectTargetService[];
    let selectedService = service ? service.serviceId : -1;

    ListingsModule.setTryItParam({
      listingId: Number(this.currentListing.listingId),
      params: {
        projectEnvironmentId: projectEnvironmentId?.key,
        serviceId: selectedService,
      },
    });
  }

  get tryItServiceEnvironment(): string {
    const serviceOption = this.serviceEnvironmentOptions.find(
      env => env.key === this.tryItServiceId,
    );
    return serviceOption ? serviceOption.value : '';
  }

  set tryItServiceEnvironment(value: string) {
    const serviceOption = this.serviceEnvironmentOptions.find(
      env => env.value === value,
    );

    ListingsModule.setTryItParam({
      listingId: Number(this.currentListing.listingId),
      params: {
        serviceId: serviceOption?.key,
      },
    });
  }

  get connectLink(): RawLocation {
    return {
      name: RouteNames.ListingConnect,
      params: addAnalyticsRouteParams(
        {
          listingId: (this.currentListing.listingId || -1).toString(),
        },
        {
          listingName: this.currentListing.listingName,
          listingType: this.currentListing.listingTypeName,
        },
      ),
    };
  }

  get connectAnalytics(): ClickData {
    return {
      clickTarget: 'listing-details-try-it-context-connect-button',
      listingName: this.currentListing.listingName,
      listingType: 'API',
    };
  }

  get tryItEnabled(): boolean {
    let tryItOptionEnabled = false;
    if (this.section.sectionOptions) {
      tryItOptionEnabled = (this.section.sectionOptions as APIListingSectionOptions)?.tryItEnabled;
    }
    return FeatureFlagsModule.tryitEnabled && tryItOptionEnabled;
  }

  get compiledMarkdown(): Promise<string> {
    if (this.section.sectionContentUrl) {
      const url: string = this.section.sectionContentUrl;
      const { githubRepo } = this.currentListing.extendedProperties;
      if (githubRepo) {
        const [owner, name] = githubRepo.full_name.split('/');
        const args: Parameters<typeof API.getRepoFileContents> = [
          owner,
          name,
          this.section.sectionContentUrl,
        ];
        if (this.$route.query.version) {
          args.push(this.$route.query.version.toString());
        }
        return API.getRepoFileContents(...args)
          .then(r => r.data)
          .then(md => marked(md, true))
          .then(html => {
            return replaceLinkedRepoRelativeLinks(html, githubRepo, url, this.versionQuery);
          });
      }
      return API.getExternalDocumentation(url)
        .then(r => r.data)
        .then(html => {
          return replaceLinkedUrlRelativeLinks(html, url);
        })
        .then(md => marked(md, { baseUrl: url }));
    }
    return Promise.resolve(marked(this.sectionContent, { sanitize: false }));
  }

  get versionQuery(): string {
    const v = this.$route.query.version;
    if (Array.isArray(v)) {
      // assigning this is necessary because typescript is kind of dumb here
      // not an error in 3.6 though
      const v0 = v[0];
      if (v0 !== null) {
        return v0;
      }
    } else if (v) {
      return v;
    }
    return '';
  }

  get path() {
    if (this.isDraft) {
      return this.draftSwaggerUrl;
    }
    const p = this.section.sectionContentUrl;
    if (p && p.startsWith('http')) {
      return getProxyUrl(safeUrl(p));
    }
    // if no http, it's the local path to our endpoint
    // return p;
    this.makeFileUrl(this.section.sectionContent);
    return undefined;
  }

  get draftSwaggerUrl() {
    if (this.section.sectionTemplateType === ListingSectionTemplateTypes.OpenApi) {
      if (this.section.sectionContentUrl) {
        // api spec overflow y some hidden
        return getProxyUrl(this.section.sectionContentUrl);
      }
      this.makeFileUrl(this.section.sectionContent);
    }
    return undefined;
  }

  private getTryItContextFormPopulatedError() {
    if (this.tryItProjectId === -1) {
      return 'Please select a PROJECT from Try-It Context panel above.';
    }
    if (this.tryItProjectEnvironment === '') {
      return 'Please select a PROJECT ENVIRONMENT from Try-It Context panel above.';
    }
    if (this.tryItServiceId === -1) {
      return 'Please select a SERVICE ENVIRONMENT from Try-It Context panel above.';
    }
    return null;
  }

  private setApiServer(specJSON: any) {
    const specVersion = this.getOpenApiVersionFromSpec(specJSON);
    switch (specVersion) {
      case '3':
        specJSON.servers = [{
          url: `https://${this.apiServerBaseUrl}${this.selectedServiceProxyPath}`,
        }];
        break;
      case '2':
      default:
        specJSON.host = `${this.apiServerBaseUrl}${this.selectedServiceProxyPath}`;
        break;
    }
  }

  private getOpenApiVersionFromSpec(specJSON: any): string {
    let versionKey = 'swagger';
    if ('openapi' in specJSON) {
      versionKey = 'openapi';
    }
    return specJSON[versionKey].split('.').shift();
  }

  public onRapiDocSpecLoaded(e: CustomEvent) {
    if (e.detail.specLoadError) {
      this.specLoadError = true;
    }
  }

  public onRapiDocBeforeTry(e: any) {
    const error = this.getTryItContextFormPopulatedError();
    if (error) {
      openErrorSnackbar.call(this, error);
      document.getElementById('layout-main')?.scrollTo(0, 0);
      this.isToggled = true;
      e.detail.controller.abort();
      return;
    }
    const proxyUrl = [
      axios.defaults.baseURL,
      URLs.TryItTryOpenApi
    ].join('');
    const url = new URL(e.detail.request.url);
    const headers = new Headers(e.detail.request.headers || {});
    const targetEnvId = this.serviceEnvironmentOptions.find(({key}) => key === this.tryItServiceId)?.envId;
    headers.append('x-try-it', 'true');
    headers.append('x-try-it-redirect-host', encodeURIComponent(proxyUrl));
    headers.append('x-try-it-project', String(this.tryItProjectId));
    headers.append('x-try-it-project-env', String(this.tryItProjectEnvironment));
    headers.append('x-try-it-listing-version', String(this.currentListing.listingVersionId));
    headers.append('x-try-it-platform', String(this.currentListing.platform?.mnemonic));
    headers.append('x-try-it-api-path', encodeURIComponent(`${url.pathname}${url.search ? url.search : ''}`));
    e.detail.request.headers = headers;
  }

  public makeFileUrl(str: string): string {
    let contentType = 'text/json';
    this.$nextTick(() => {
      const mountedRapiDoc = this.$refs['listing-details-rapidoc'];
      if(mountedRapiDoc) {
        try {
          const specJson = JSON.parse(str);
          if (this.tryItEnabled) {
            this.setApiServer(specJson);
          }
          (mountedRapiDoc as any).loadSpec(specJson || {});
        } catch(err) {
          try {
            const specJson = Yaml.parse(str);
            if (this.tryItEnabled) {
              this.setApiServer(specJson);
            }
            (mountedRapiDoc as any).loadSpec(specJson || {});
          } catch(yerr) {
            this.section.sectionContent = '';
          }
        }
      }
    })
    URL.revokeObjectURL(this.section.sectionContent);
    return URL.createObjectURL(new Blob([str], { type:  contentType}));
  }

  public get currentListing(): FullListing {
    if (this.listing !== undefined) {
      this.isDraft = true;
      return this.listing;
    } else {
      return ListingsModule.currentListing;
    }
  }

  get tab(): ListingSection {
    return this.activeTab;
  }

  get section(): ListingSection {
    const ds = this.currentListing.extendedProperties.documentationSections;
    const sections: ListingSection[] = ds.map(s => s.sectionInfo);
    let sct: ListingSection;
    if (this.isDraft) {
      sct = this.tab;
    } else {
      sct = sections.filter(section => slugify(section.sectionName) === this.pageName)[0];
    }
    return sct;
  }

  get sectionName() {
    if (this.section === undefined) return '';
    return this.section.sectionName;
  }

  get sectionTemplateType(): string {
    if (this.section === undefined) return '';

    return this.section.sectionTemplateType;
  }

  get sectionContent(): string {
    if (this.section === undefined) {
      return '';
    }
    if (
      this.section.sectionTemplateType === ListingSectionTemplateTypes.Markdown &&
      this.section.sectionContent
    ) {
      // attempt to remove frontmatter, some of the results have malformed frontmatter though...
      return this.section.sectionContent.replace(/^---[\s\S]*---/, '');
    }
    return this.section.sectionContent;
  }

  get pageName(): string {
    return this.$route.params.info;
  }

  handleMarkdownClick(e: MouseEvent) {
    // to track link clicks in the markdown
    const { target } = e;
    if (target) {
      let t = target as HTMLElement;
      let parentLink = t.closest('a');
      let href = '';
      if (t.tagName === 'A') {
        href = (t as HTMLAnchorElement).href;
      } else if (parentLink) {
        href = parentLink.href;
      }

      if (href) {
        analyticsClick({
          clickTarget: 'listing-documentation-link',
          link: href,
        });
      }
    }
  }

  async getConnectedProjects() {
    try {
      const listingId = Number(this.currentListing.listingId);
      const { data } = await getConnectedProjects(listingId);
      this.connectedProjects = data.projects;
    } catch (ex) {
      this.connectedProjects = [];
    }
  }

  @Watch('tryItServiceEnvironment', { immediate: true })
  public onChangeTryItServiceEnvironment() {
    if (FeatureFlagsModule.tryitEnabled && this.section) {
      this.makeFileUrl(this.section.sectionContent);
    }
  }

  mounted() {
    const platformName = this.currentListing.platform?.mnemonic || Platform.AZURE;
    ProcessEnvsModule.setPlatform(platformName);
    if (FeatureFlagsModule.tryitEnabled) {
      let {
        referrerProjectId,
        referrerProjectEnvId,
        referrerServiceId
      }: {
        referrerProjectId?: number,
        referrerProjectEnvId?: number,
        referrerServiceId?: number,
      } = this.$route.query;

      referrerProjectId = referrerProjectId ? Number(referrerProjectId) : -1;
      referrerProjectEnvId = referrerProjectEnvId ? Number(referrerProjectEnvId) : -1;
      referrerServiceId = referrerServiceId ? Number(referrerServiceId) : -1;

      this.getConnectedProjects().then(() => {
        this.$nextTick().then(() => {
          ListingsModule.setTryItParam({
            listingId: Number(this.currentListing.listingId),
            params: {
              projectId: referrerProjectId,
              projectEnvironmentId: referrerProjectEnvId,
              serviceId: referrerServiceId,
            },
          });
        });
      });
    }
  }
}
