
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AutocompleteBase, Choice } from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';

interface AutocompleteEvent extends CustomEvent {
  detail: {
    selectedChoices?: { label: string; value: any }[];
    selectedChoice?: { label: string; value: any };
    value: string;
  };
}

@Component({
  inheritAttrs: false,
})
export default class GAutocomplete extends Vue {
  @Prop([String, Object]) readonly value!: undefined | { label: string; value: string };
  @Prop({type: Array, default: () => []}) readonly choices!: AutocompleteBase['choices'];
  @Prop({ type: Function, required: false }) readonly getChoices!: AutocompleteBase['getChoices'];
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly async!: boolean;
  @Prop({ default: 'Invalid input' }) readonly validationMessage!: string;

  get choiceList(): Choice[] {
    if (this.v) {
      const { value } = this.value as Choice;
      if (this.choices.some(choice => choice.value === value)) {
        return this.choices;
      }
      return [this.value as Choice, ...this.choices];
    }
    return this.choices;
  }

  get v() {
    return this.value ? this.value.value : '';
  }

  async mounted() {
    if (this.v) {
      Vue.nextTick(async () => {
        const ac = this.$refs.ac as AutocompleteBase;
        if (this.choices && this.choices.length) {
          ac.choices = this.choices;
        } else if (this.getChoices) {
          const choices = await this.getChoices(this.v || '') as Choice[];
          ac.choices = choices;
        } else {
          ac.choices = [this.value as Choice];
        }
        await ac.updateComplete;
        ac.value = this.v;
        // eslint-disable-next-line
        // @ts-ignore // needed in order to make initial value work for some reason
        ac.inputValue = this.v;
      });
    }
  }

  get autoCompleteEventHandlers() {
    return {
      closed: (e: Event) => e.stopPropagation(),
      opened: (e: Event) => e.stopPropagation(),
    };
  }

  input(e: AutocompleteEvent) {
    const keys = new Set(Object.keys(e.detail));
    if (keys.has('selectedChoice')) {
      this.$emit('input', e.detail.selectedChoice);
    } else if (keys.has('selectedChoices')) {
      this.$emit('input', e.detail.selectedChoices?.[0]);
    }
  }
}
